healcode-widget .healcode.enrollments .navigation {
    display: none !important;
}

healcode-widget .healcode.enrollments .header {
    display: none !important;
}

/* healcode-widget .healcode-button {
    display: none !important;
} */

healcode-widget[data-type="enrollments"] {
    width: 100%;
}

healcode-widget div.healcode.enrollments .enrollment, div.healcode-enrollments ol.enrollment_list.list_view li {
    background-color: rgba(28,28,28,1) !important;
    border: none;
}

healcode-widget div.healcode.enrollments.modern-theme-wrapper {
    padding-top: 0;
}

healcode-widget div.healcode.enrollments.modern-theme-wrapper * {
    line-height: 1.5;
}

healcode-widget div.healcode.enrollments.modern-theme-wrapper .filtered_collection, healcode-widget div.healcode ol.enrollment_list li div.trainer span a, healcode-widget div.healcode ol.enrollment_list li div.mbo_class span a {
    color: white !important;
}

healcode-widget div.healcode.enrollments .enrollment, div.healcode.enrollments .healcode-course, div.healcode.enrollments.modern-theme-wrapper .filtered_collection>li {
    padding: 20px !important;
    border-width: 1px 0 0 !important;
    border-color: rgba(210,241,62,1) !important;
    border-style: solid !important;
    border-radius: 0
}

healcode-widget div.healcode.enrollments.modern-theme-wrapper .filtered_collection>div {
    border: none;
    margin: 0;
}

div.healcode.enrollments.modern-theme-wrapper .filtered_collection.enrollment_list li {
    align-items: center !important;
}

healcode-widget div.healcode span.trainer a, div.healcode h2.healcode-enrollment-name a, div.healcode span.healcode-trainer-name {
    color: white !important;
    font-size: 1.1rem !important;
    font-weight: 700 !important;
}

healcode-widget h2.healcode-enrollment-name a {
    pointer-events: none;
}

healcode-widget div.healcode.enrollments.modern-theme-wrapper *, healcode-widget div.healcode ol.enrollment_list li div.trainer span a {
    font-family: "Menlo", Lato;
    font-size: 12px;
}

healcode-widget div.healcode.enrollments.modern-theme-wrapper .healcode-date-area {
    font-weight: 700;
    padding-bottom: 5px;
}
